import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_SUCCESS,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
	SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
	GET_USER_ME_SUCCESS,
	REFRESH_MENU,
	GET_ALL_PROJECTS, GET_ALL_PROJECTS_SUCCESS,
} from '../constants/Auth';
import utils from "../../utils";

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
	user: {},
	projects: [],
	allProjects: [],
	refreshMenu: false,
};

//Init localStorage
const project = utils.getProjectLocation();
if(utils.isValidProject(project) && localStorage.getItem('project') !== project) {
	localStorage.setItem('project', utils.getProjectLocation());
	localStorage.removeItem('idPatient');
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case SIGNIN_SUCCESS:
			return {
				...state,
				loading: false,
				redirect: utils.getRouteLoginSuccess(),
				token: action.payload.token,
				user: action.payload.user,
				projects: action.payload.projects,
			};
		case GET_USER_ME_SUCCESS:
			return {
				...state,
				loading: false,
				// redirect: utils.getInitRootProject(),
				// token: action.payload.token,
				user: action.payload.user,
				projects: action.payload.projects,
			};

		case GET_ALL_PROJECTS_SUCCESS:
			console.log("payload", action.payload)
			return {
				...state,
				allProjects: action.payload,
			};


		case AUTHENTICATED:
			localStorage.setItem(AUTH_TOKEN, action.token);
			return {
				...state,
				loading: false,
				redirect: utils.getInitRootProject(),
				token: action.token
			};
		case SHOW_AUTH_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: utils.getRouteLogin(),
				loading: false
			}
		}
		case SIGNUP_SUCCESS: {
			return {
			  ...state,
			  loading: false,
			  token: action.token
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case REFRESH_MENU: {
			return {
				...state,
				refreshMenu: !state.refreshMenu
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		default:
			return state;
	}
}

export default auth