import React from 'react'

import { Table, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";

class TableSearch extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      searchText: '',
      searchedColumn: '',
    };
  }

  transformColumn(headers=this.props.columns) {
      return headers.map(o => {
        if(!o.render) {
          return {
            title: o.title,
            dataIndex: o.dataIndex,
            key: o.key ?? 0,
            fixed: o.fixed,
            sorter: {
              compare: (a, b) => {
                const aValue = a[o.key];
                const bValue = b[o.key];

                // Función para comprobar si es un número
                const isNumber = value => {
                  return /^-?\d+(\.\d+)?$/.test(value);
                };

                // Comprobar si es numérico
                if (isNumber(aValue) && isNumber(bValue)) {
                  return parseFloat(aValue) - parseFloat(bValue);
                }
                // Comprobación de fecha
                else if (moment(aValue).unix() && moment(bValue).unix()) {
                  return moment(aValue).unix() - moment(bValue).unix();
                }
                // Comprobar si es string
                else if (typeof aValue === 'string' && typeof bValue === 'string') {
                  return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
                }

                return 0;
              },
            },
            ...this.getColumnSearchProps(o.dataIndex),
          }
        }
      else {
          return o
        }
      })
  }

  transformDataSource(data=this.props.data) {
    return data
  }


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]?.toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text?.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }
  render() {
    return <Table pagination={this.props.pagination} columns={this.transformColumn()} dataSource={this.transformDataSource()} onChange={this.onChange} scroll={{ x: 1 }}/>;
  }
}
export default TableSearch;