const isHTTPS = window.location.protocol === "https:";

const dev = {
	API_ENDPOINT_URL: isHTTPS
		? 'https://innovacionhuvm.sevilla.dmsas.sda.sas.junta-andalucia.es/servicioandaluzdesalud'
		: 'http://10.232.0.226:8083/servicioandaluzdesalud/hhuuvr/esaludpre/MirthOperaciones/ITCBIO',
	API_ANALYTICS: isHTTPS
		? 'https://innovacionhuvm.sevilla.dmsas.sda.sas.junta-andalucia.es:8083'
		:'http://10.232.0.226:8084/servicioandaluzdesalud/hhuuvr/esaludpre/MirthOperaciones/ITCBIO',
	API_FILES: isHTTPS
		? 'https://innovacionhuvm.sevilla.dmsas.sda.sas.junta-andalucia.es:8084/app'
		: 'http://10.232.5.179/app',
};

const prod = {
	API_ENDPOINT_URL: 'https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/esaludpre/MirthOperaciones/ITCBIO',
	API_FILES: 'https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/innovacion/app'
};

const test = {
  API_ENDPOINT_URL: 'https://api.test.com'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
