import {GET_CHAT, GET_CHAT_SUCCESS, ADD_OUTGOING_MESSAGE} from "../constants/Chat";


const initState = {
  chat: []
}

const chat = (state=initState, action) => {
  switch (action.type) {
    case GET_CHAT_SUCCESS:
      return {
        chat: action.payload
      }
    case ADD_OUTGOING_MESSAGE:
      return {
        ...state,
        chat: state.chat.map(chat =>
          chat.name === action.chatId ? {
            ...chat,
            msg: [...chat.msg, action.message]
          } : chat
        )
      };
    default:
      return state;
  }
}
export default chat;