import {
  SIGNIN,
  SIGNIN_SUCCESS,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  GET_USER_ME,
  GET_USER_ME_SUCCESS,
  REFRESH_MENU, GET_ALL_PROJECTS, GET_ALL_PROJECTS_SUCCESS,
} from '../constants/Auth';

export const signIn = (credential) => {
  return {
    type: SIGNIN,
    payload: credential
  }
};

export const signInSuccess = (user) => {
  return {
    type: SIGNIN_SUCCESS,
    payload: user
  }
};

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token
  }
};

export const signOut = () => {
  return {
    type: SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
};

export const getUserMe = () => {
  return {
    type: GET_USER_ME
  };
};

export const getAllProjects = () => {
  return {
    type: GET_ALL_PROJECTS
  };
};


export const getAllProjectsSuccess = (allProjects) => {
  return {
    type: GET_ALL_PROJECTS_SUCCESS,
    payload: allProjects
  };
};
export const getUserMeSuccess = (user) => {
  return {
    type: GET_USER_ME_SUCCESS,
    payload: user,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token
  };
};


export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const refreshMenu = () => {
  return {
    type: REFRESH_MENU,
  };
};
