import {transformRow} from "../../../../utils/TransformFormDashboard";

export const getData = (data) => {
    return ({
        tipo: "OPERACION_DASHBOARD",
        filter: transformBase64ToArray(data.filter),
        id_dashboard: data.id_dashboard,
        id_secondary: data.id_secondary
    });
}


const transformBase64ToArray = (data) => {
    let result = null;
    for(let key in data) {
        if(data[key].includes('base64-')){
            const base64ToString = Buffer.from(data[key].substring(7), "base64").toString();
            const array = JSON.parse(base64ToString)
            if(Array.isArray(array))
                result = array.map(transformObjectToValue)
            else
                result = transformObjectToValue(array)
            data[key] = result;
        }
    }
    return data;
}

const transformObjectToValue = (element) => {
    return element.key;
}
export const getSearchInput = (data) => {
    return ({
        tipo: "OPERACION_SEARCH_3",
        search: data.value,
        id_item_search: data.idSearch
    });
}

export const responseSearchInput= ({ data }) => {
    return ({
        options: data.response_options?.map(transformSearchResponseOptions)
    });
}

const transformSearchResponseOptions = (data) => {
    return (
      {
          key: data.db_value,
          label: data.display_value
      }
    )
}

export const responseDashboard = ({ data = { dashboard: {} } }) => {
    const { dashboard } = data;
    return ({
        id: dashboard.id,
        title: dashboard.title,
        type: dashboard.type,
        refresh: dashboard.refresh,
        sound: dashboard.sound ?? false,
        rows: dashboard.rows?.map(transformRow),
    });
}
