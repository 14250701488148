import { Widget } from './index';
import {Table} from "antd";
import React, {useState} from "react";
import Card from "antd/es/card";
import {StlViewer} from "react-stl-viewer";


const style = {
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
}
const url = "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl"
export class WidgetStlObject extends Widget {

  constructor(obj={}){
    super(obj);

    this.value = obj.value;
  }
  getComponent() {
    const [volume, setVolume] = useState(0);

    return () => (
        <StlViewer
            width={1000}
            height={500}
            url="https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl"
            groundColor="rgb(255, 255, 255)"
            objectColor="#008675"
            skyboxColor="rgb(255, 255, 255)"
            gridLineColor="rgb(0, 0, 0)"
            lightColor="rgb(255, 255, 255)"
            volume={setVolume}

        />
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value;
    return this;
  }
}