import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        {/*<Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => importation(`./authentication/login`))} />*/}
        <Route path={`${AUTH_PREFIX_PATH}/:project/login`} component={lazy(() => import(`./authentication/login`))} />
        <Route path={`${AUTH_PREFIX_PATH}/:project/error`} component={lazy(() => import(`./errors/error-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/:project/fixed/dashboard/:dashboard`} component={lazy(() => import(`../app-views/dashboard`))} />
        <Route path={`${AUTH_PREFIX_PATH}/:project/fixed/form/:form`} component={lazy(() => import(`../app-views/form`))} />
          <Route path={`${AUTH_PREFIX_PATH}/:project/redirect`} component={lazy(() => import(`../app-views/redirect`))} />

          <Redirect from={`${AUTH_PREFIX_PATH}/:project`} to={`${AUTH_PREFIX_PATH}/:project/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

