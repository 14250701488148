import {ApiService, handlerError} from "../ApiService";
import {
  getFormData,
  printFormData,
  responseForm,
  responseSaveForm,
  saveFormData,
  saveFormDataWithParams
} from "./transform";

const FormService = {};




const exampleResponse =
  {data: {
      "tipo": "OPERACION_6",
      "codigo": "200",
      "descripcion": "OK",
      "form": {
        "id": "350",
        "title": "Form",
        "type": "form",
        "buttons": [
          {
            "name": "Guardar",
            "type": "request",
            "color": "primary"
          }
        ],
        "rows": [
          {
            "type": "tab",
            "tabs": [
              {
                "title": "Criterios I/O",
                "rows": [
                  {
                    "type": "row",
                    "columns": [
                      {
                        "title": "Widget Action",
                        "type": "form-action",
                        "size": 24,
                        "fields": [
                          {
                            "name": "remoteSearch",
                            "label": "Remote Search",
                            "idSearch": "ma_cie10",
                            "type": "remotesearch",
                            "mode": "single",  // "single" || "multiple"
                            "size": 12
                          },
                          {
                            "name": "DASVSG_Actual",
                            "label": "DASVSG_Actual",
                            "type": "number",
                            "size": 6,
                            "value": "3"
                          },
                          {
                            "name": "file",
                            "label": "file",
                            "type": "file",
                            "size": 6,
                            "value": "26366.jpg"
                          },
                          {
                            "name": "calculated",
                            "label": "Cal",
                            "type": "calculated",
                            "size": 6,
                            "formula": "((form.getFieldValue('DASVSG_Actual')) < 2.6  &&'REMISIÓN VSG' || (form.getFieldValue('DASVSG_Actual') > 2.5 && form.getFieldValue('DASVSG_Actual') < 3.2) &&'BAJA VSG')"

                          },
                          {
                            "name": "calculated",
                            "label": "Cal",
                            "type": "calculated",
                            "size": 6,
                            "formula": "Math.log(form.getFieldValue('DASVSG_Actual'))"

                          }
                        ],
                        "buttons": [{
                          "name": "Guardar",
                          "type": "save",
                          "color": "primary"
                        },{
                          "name": "Limpiar",
                          "type": "clear",
                          "color": "secondary"
                        },{
                          "name": "Download",
                          "type": "download",
                          "color": "dashed",
                          "url": "http://google.com"
                        },
                          {
                            "name": "Print text",
                            "type": "printText",
                            "color": "dashed",
                            "operation": "OPERACION_PRINT_TXT"
                          }]
                      }
                    ]
                  },
                  {
                    "type": "row",
                    "columns": [
                      {
                        "type": "text",
                        "title": "title 1",
                        "size": 24,
                        "value": "Criterios de exclusión"
                      },
                      {
                        "type": "table-form",
                        "title": "title 1",
                        "size": 24,
                        "value": "Criterios de exclusión",
                        "fields": [
                          {
                            "name": "header",
                            "type": "header",
                            "value": "Titulo"
                          },
                          {
                            "name": "name",
                            "label": "Text",
                            "type": "text",
                            "size": 6
                          },
                          {
                            "name": "radio",
                            "label": "Cal",
                            "type": "multiselect",
                            "size": 6,
                            "options": [{"key": "1", "label": "test 1"}, {"key": "2", "label": "test 2"}]

                          },
                          {
                            "name": "address",
                            "label": "Calculated",
                            "type": "number",
                            "size": 6
                          }],
                        "rows": [
                          {
                            "name": "Enchufe",
                            "age": "Bueno",
                          },
                          {
                            "name": "Cable",
                            "age": "Malo"
                          },
                          {
                            "name": "Cable",
                            "age": "Bueno",
                          },
                          {
                            "name": "Enchufe",
                            "age": "Malo",
                            "address": ""
                          }
                        ]

                      },
                      // {
                      //   "title": "Widget Action",
                      //   "type": "form-action",
                      //   "size": 24,
                      //   "fields": [
                      //     {
                      //       "name": "text1",
                      //       "label": "Text",
                      //       "type": "text",
                      //       "size": 6,
                      //       "value": "3"
                      //     },
                      //     {
                      //       "name": "text2",
                      //       "label": "Text",
                      //       "type": "text",
                      //       "size": 6
                      //     },
                      //     {
                      //       "name": "calculated",
                      //       "label": "Cal",
                      //       "type": "calculated",
                      //       "size": 6,
                      //       "condition": "form.getFieldValue('text1')?.includes('3')"
                      //
                      //     },
                      //     {
                      //       "name": "radio",
                      //       "label": "Cal",
                      //       "type": "multiselect",
                      //       "size": 12,
                      //       "condition": "form.getFieldValue('text1') == '3'",
                      //       "options": [{"key": "1", "label": "test 1"}, {"key": "2", "label": "test 2"}]
                      //
                      //     }]
                      // },
                      // {
                      //   "title": "Widget Action",
                      //   "type": "form-action",
                      //   "size": 24,
                      //   "fields": [
                      //     {
                      //       "name": "text3",
                      //       "label": "Text",
                      //       "type": "text",
                      //       "size": 6,
                      //       "condition": "form.getFieldValue('radio')?.includes('1')"
                      //     },
                      //     {
                      //       "name": "text4",
                      //       "label": "Text",
                      //       "type": "text",
                      //       "size": 6,
                      //     }
                      //   ],
                      //   buttons:[
                      //     {
                      //       "name": "Buscar",
                      //       "type": "save",
                      //       "color": "primary"
                      //     }
                      //   ]
                      // }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }};



FormService.get = ({payload: data, fake}) => {
  return (fake
    ? Promise.resolve(exampleResponse)
    :ApiService.post('', getFormData(data)))
    .then(responseForm)
    .catch(handlerError);
}

FormService.save = (data) => {
  return ApiService.post('', saveFormData(data))
    .then(responseSaveForm)
    .catch(handlerError)
}

FormService.saveWithParams = (data) => {
  return ApiService.post('', saveFormDataWithParams(data))
    .then(responseSaveForm)
    .catch(handlerError)
}

FormService.callApi = (data) => {
  return ApiService.post('', printFormData(data))
    .then()
    .catch(handlerError)
}
export default FormService