import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Innovación';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const HEADER_TOKEN = 'token';
export const PROJECT_GLOBAL = 'global';
export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'es',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '#ffffff',
	mobileNav: false
};
export const PROJECTS = {
	global: null,
	smartmigraine: '28',
	cronicare: '29',
	hero: '30',
	demo2 : '38',
	ictus: '6',
	rinobase:"43",
	gestion: '49',
	info: '54',
	dashboard: '56',
	reuma: '47',
	oncoand: '51',
	pointofcare: '59',
	bd_laboratorio : '60',
	geohealth: '44',
	sicapv: '2',
	recardio : '40',
	reumatolog: '47',
	alergolog: '61',
	calidad: '63',
	preanestesas: '19',
	tecipot: '64',
	gestionhospitalaria: '65',
	fracturas: '66',
	direccion: '68',
	peticiones: '69',
	prostata: '55',
	dam : '70',
	biobanco: '71',
	unidades: '72',
	ginecologia	:	"73"	,
	orl	:	"74"	,
	salmental	:	"75"	,
	digestivo	:	"76"	,
	alergologia	:	"77"	,
	derma	:	"78"	,
	cped	:	"79",
	cot	:	"80"	,
	endocrino	:	"81"	,
	urgencias	:	"82"	,
	hematologia	:	"83"	,
	cplast	:	"84"	,
	medint	:	"85"	,
	mednuc	:	"86"	,
	nefrologia	:	"87"	,
	ctorac	:	"88"	,
	oncomed	:	"89"	,
	anestesiologia	:	"90"	,
	ccv	:	"91"	,
	neurologia	:	"92"	,
	onco_rad	:	"93"	,
	eeii	:	"94"	,
	oftalmologia	:	"95"	,
	cuidadosintensivos	:	"96"	,
	iradiodiagnostico	:	"97"	,
	ap	:	"98"	,
	pediatria	:	"99"	,
	cmax	:	"100"	,
	cgral	:	"101"	,
	urologia	:	"102"	,
	bacteriemias: "103",
	neurofisiologia: "105",
	registro_reuma : "106",
	neumologia :"107",
	apbi : "108",
	rehab :"109",
	xolomon : "110",
	inflamacion_pulmon :"111",
	bd_reumatologia : "112",
	ud_admision: "113",
	bddiabetesmellitus : "114",
	salud_mental_sl : "115",
	misiones_a1 : "116",
	hsl : "117",
	egfr_micro : "118",
	laboratorio : "119",
	nilo : "120",
	implantlabs : "121",
	BD_Nefrologia : "122",
	neurocirugia : "123",
	poct :"124",
	ihan: "125",
	adarnat: "126",
	reuma_cuestionarios:"127",
	pepsur : "128",
	neonatologia : "129",
	mpreventiva :"130",
	farmacia: "131",
	inf_microcitico:"132",
	melatoms :"133",
	accesos_vasculares : "134",
	centros_salud : "135",
	registro_ojo_seco : "136",
	telerehabilitacion_tep : "137",
	fisevi : "138",
	alergia_farmacos : "139",
	neumologia_ped: "140",
	oncoand_melanoma : "141",
	oncoand_mama : "142",
	geoasma : "143",
	seguimiento_bariatrica : "144",
	recroand : "145",
	registro_queratocono : "146",
	registro_catarata : "147",
	registro_reanimacion : "148",
	registro_suicidios : "149",
	estudio_prep : "150",
	ins_Limabr : "151",
	comisiones : "152",
	recufevi : "153",
	rercand : "155",
	obstetricia : "156",
	epa_hcc : "157",
	trabajo_social: "158",
	control_prurito: "159",
	estudio_prueba : "160",
	bd_radiofisica : "161",
	alerta_vadc : "162",
	urgencias_sm : "163",
	riesgo_cv : "164",
	cateter_nefro : "165",
	SM_Salud_fisica : "166",
	bd_braquiterapia : "167",
	importacion_datos : "168",
	pared_obesidad : "169",
	oncoand_gammapatias : "170",
	dialisis_domiciliaria : "171",
	app_epid : "172",
	ud_amiloidosis : "173",
	app_direccion : "174",
	primavera : "175",
	unicom : "176",
	consulta_dra : "177",
	dir_economica : "178",
	estudio_espb : "179",
	app_enf : "180",
	kfr : "181",
	app_limpieza : "182",
	painthera : "183",
	USMIJ : "184",
	NUTIC : "185",
	hipertension_pulmonar : "186",
	sca : "187",
	suika : "188",
	int_estructural : "189",
	bioattrh24 : "190",
	uarmi : "191",
	banco_leche : "192",
	litotricia_coronaria : "193",
	chemsex : "194",
	estudio_mho : "195",
	sm_enlaceic : "196",
	oncoand_pediatrico : "197",
	oncoand_neuroendo : "198",
	oncoand_colorrectal : "199",
	oncoand_urologico : "200",
	oncoand_cabezacuello : "201",
	oncoand_gastroesof : "202",
	oncoand_linfoma : "203",
	oncoand_tiroides : "204",
	oncoand_snc  : "205",
	oncoand_ocular :  "206",
	demo_fabis :  "207",
	app_reuma : "208",
	ud_farmacologia : "209",
	preanestesas_v2 : "210",
	recardio_obesidad : "211",
	ruler : "212",
	stc : "213",
	lucia_citas : "214",
	progresion_em : "215",
	bd_digestivo : "216",
	caronte : "217",
	oncoand_gine : "218",
	pros_practica_clinica : "219",
	h_sierra_norte : "220",
	facturacion : "221",
	pdl1 : "223",
	conizaciones : "224",
	opengeohealth : "225",
	ohsiris : "226",
	formacion : "229"
};

export const DESCRIPTIONS = {
	global: 'GLOBAL ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	cronicare: 'cronicare ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	hero: 'hero ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	ictus: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	gestion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	info: 'Proyecto de gestion de información.',
	dashboard: 'Proyecto de gestion de información.',
	reuma: 'Proyecto de Rehumatología.',
	oncoand: 'Proyecto de investigación para la definición, implementación y validación de un sistema de Learning Health System orientado al soporte en la toma de decisiones de los comités de tumores del Hospital Universitario Virgen Macarena\n.',
	geohealth: 'Victor desertor',
	sicapv: 'SICAPV+ es una solución tecnológica de soporte a la gestión y el seguimiento de PVCV en Andalucía de manera integrada con la práctica clínica habitual ',
	reumatolog: 'Estudio Reuma',
	calidad: 'El objetivo principal de la Unidad de Calidad es desarrollar las actividades necesarias para la gestión de la calidad en nuestro Hospital, orientando siempre el trabajo a la mejora continua de la calidad asistencial prestada a nuestros usuarios.',
	preanestesas: 'Este proyecto plantea una mejora al proceso asociado a la evaluación preanestésica, promoviendo el desarrollo de nuevos servicios integrados de atención a través de los terminales móviles de los pacientes y permitiendo así reducir el consiguiente consumo de recursos sanitarios asociado.',
	tecipot: 'Proyecto Tecipot.',
	fracturas: 'Proyecto Fracturas.',
	gestionhospitalaria: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.',
	direccion: 'Cuadro de mando para dirección',
	alergolog: 'Proyecto de Alergología',
	peticiones: 'Peticiones',
	prostata: 'Proyecto ACPROAND',
	smartmigraine: 'SmartMigraine',
	biobanco: 'Proyecto Biobanco',
	rinobase: 'Proyecto Rinobase',
	unidades: 'Proyecto Unidades',
	recardio: "Sistema de seguimiento de pacientes de Rehabilitación Cardiovascular",
	dam : "Registro  pilotaje dispositivos de avance mandibular",
	ginecologia	:	"Unidad Ginecologia"	,
	orl	:	"Unidad ORL"	,
	salmental	:	"Unidad Salud Mental"	,
	digestivo	:	"Unidad Digestivo"	,
	alergologia	:	"Unidad Alergologia"	,
	derma	:	"Unidad Dermatologia"	,
	cped	:	"Unidad Cirugia Pediatrica"	,
	cot	:	"Unidad Cirugia OyT"	,
	endocrino	:	"Unidad Endocrino"	,
	urgencias	:	"Unidad Urgencias"	,
	hematologia	:	"Unidad Hematologia"	,
	cplast	:	"Unidad Cirugia Plastica"	,
	medint	:	"Unidad Medicina Interna"	,
	mednuc	:	"Unidad Medicina Nuclear"	,
	nefrologia	:	"Unidad Nefrologia"	,
	ctorac	:	"Unidad Cirugia Toracica"	,
	oncomed	:	"Unidad Oncologia Medica"	,
	anestesiologia	:	"Unidad Anestesiologia"	,
	ccv	:	"Unidad Cardiologia y CCV"	,
	neurologia	:	"Unidad Neurologia"	,
	onco_rad	:	"Unidad Onco Radioterapica"	,
	eeii	:	"Unidad EEII"	,
	oftalmologia	:	"Unidad Oftalmologia"	,
	cuidadosintensivos	:	"Unidad Cuidados Intensivos"	,
	radiodiagnostico	:	"Unidad Radiodiagnostico"	,
	ap	:	"Unidad AP"	,
	pediatria	:	"Unidad Pediatria"	,
	cmax	:	"Unidad Cirugia Maxilofacial"	,
	cgral	:	"Unidad Cirugia General"	,
	urologia	:	"Unidad Urologia"	,
	demo2: "DEMO 2",
	bacteriemias: "Proyecto bacteriemias ",
	neurofisiologia: "Proyecto Neurofisiología",
	registro_reuma: "Registro Reumatología",
	neumologia:"",
	apbi:"",
	rehab : "Unidad de Rehabilitación",
	xolomon : "XOLOMON",
	inflamacion_pulmon : "",
	bd_reumatologia : "",
	ud_admision: "Unidad de Admisión",
	bddiabetesmellitus : "Base de datos Diabetes Mellitus",
	salud_mental_sl : "Salud Mental SL",
	misiones_a1 : "MISIONES AI",
	hsl: "HSL",
	egfr_micro : "EGFR Microcítico",
	laboratorio : "Unidad de Laboratorio",
	nilo: "Estudio Virus del Nilo",
	implantlabs : "ImplantLabs",
	BD_Nefrologia : "Base de datos Unidad de Nefrología",
	neurocirugia : "Unidad de Neurocirugía",
	poct:"POCT",
	ihan:"Estudio IHAN",
	adarnat : "ADARNAT",
	reuma_cuestionarios : "Calculo de indices enfermedades inflamatorias reumatología",
	pepsur : "Registro PEPSur",
	neonatologia:"Unidad de Neonatología",
	mpreventiva : "Unidas de medicina preventiva",
	inf_microcitico : "Inflamacion Microcítico",
	melatoms : "MELATOMS",
	accesos_vasculares : "Unidad Digital de Cuidados",
	centros_salud : "Centros de Salud",
	registro_ojo_seco : "Registro Ojo Seco",
	telerehabilitacion_tep : "TEP Telerehabilitación",
	fisevi : "FISEVI",
	alergia_farmacos : "Alergia a fármacos",
	neumologia_ped : "Neumología PED",
	oncoand_melanoma : "Oncoand Melanoma",
	oncoand_mama : "Oncoand Mama",
	geoasma : "GeoAsma",
	seguimiento_bariatrica : "Consulta Enfermería Bariátrica",
	recroand : "RERCAND",
	registro_queratocono : "Registro Queratocono",
	registro_catarata : "Registro Catarata",
	registro_reanimacion : "Registro Reanimación",
	registro_suicidios : "Registro Suicidios",
	estudio_prep : "Estudio PREP",
	ins_Limabr : "Registro Insuficiencia Limbar",
	comisiones : "Comisiones",
	recufevi : "RECUFEVI",
	rercand : "RERCAND",
	obstetricia : "Unidad de Obstetricia",
	epa_hcc : "EPA HCC",
	trabajo_social: "Trabajo Social",
	control_prurito: "Control Prurito",
	estudio_prueba : "Estudio PRUEBA",
	bd_radiofisica : "Base de datos Radiofísica",
	alerta_vadc : "Alerta Vía Aérea Difícil",
	urgencias_sm : "Urgencias Salud Mental",
	riesgo_cv : "Mapa de Riesgo Cardiovascular",
	cateter_nefro : "Manejo Catéter Nefro",
	SM_Salud_fisica : "SM Salud Física",
	bd_braquiterapia : "BD Braquiterapia Oftálmica",
	importacion_datos : "Importación Datos",
	pared_obesidad : "Pared Obesidad",
	oncoand_gammapatias : "OncoAnd Gammapatías",
	dialisis_domiciliaria : "Dialisis Domicialiaria",
	app_epid : "APP Seguimiento de pacientes con Enfermedad Pulmonar Instersticial Difusa",
	ud_amiloidosis : "Unidad de Amiloidosis",
	app_direccion : "Direccion",
	primavera : "PRIMAVERA",
	unicom : "UNICOM",
	consulta_dra : "Consulta DRA",
	dir_economica : "Dirección Económica",
	estudio_espb : "Estudio ESPB",
	app_enf : "APP Enfermería",
	painthera : "Painthera",
	USMIJ : "USMIJ",
	NUTIC : "NUTIC",
	hipertension_pulmonar : "Hipertensión pulmonar",
	sca : "CI FRV tras SCA",
	suika : "suika Tomeu",
	int_estructural : "Intervencionismo Estructural",
	bioattrh24 : "BioATTRh24",
	uarmi : "UARMI",
	banco_leche : "Banco de Leche",
	litotricia_coronaria : "Litotricia Coronaria",
	chemsex : "Estudio Chemsex",
	sm_enlaceic : "Salud Mental ENLACE IC",
	oncoand_pediatrico : "OncoAnd Comisión tumores pediátricos",
	oncoand_neuroendo : "OncoAnd Comisión de tumores neuro-endocrinos",
	oncoand_colorrectal : "OncoAnd Comisión cáncer colorrectal",
	oncoand_urologico : "OncoAnd Comisión Tumores Urológicos",
	oncoand_cabezacuello : "OncoAnd Tumores Cabezacuello",
	oncoand_gastroesof : "OncoAnd Comisión tumores Gastroesofágicos",
	oncoand_linfoma : "OncoAnd Comisión Linfoma",
	oncoand_tiroides : "OncoAnd Comisión cáncer de tiroides",
	oncoand_snc  : "OncoAnd Comisión Tumores del Sistema Nervioso Central",
	oncoand_ocular :  "OncoAnd Comisión de tumores oftalmológicos",
	demo_fabis :  "DEMO FABIS",
	app_reuma : "APP Reumatología",
	ud_farmacologia : "Unidad de farmacología",
	preanestesas_v2 : "Preanestes@s V2",
	recardio_obesidad : "Recardio Obesidad",
	ruler : "Radioterapia ultra-hipofraccionada de rescate guiada por PET-TAC PSMA. Estudio prospectivo de seguridad, eficacia y calidad de vida",
	stc : "Derivacion de pacientes con sospecha de sindrome del tunel del carpo",
	lucia_citas : "Sistema de registro de citas del proyecto LUCIA",
	progresion_em : "Base de datos Progresión Esclerosis múltiple",
	bd_laboratorio : "Laboratorio base de datos",
	bd_digestivo : "Base de Datos Digestivo",
	caronte: "Estudio Caronte-A",
	oncoand_gine : "OncoAnd Comisión de tumores ginecológicos",
	pros_practica_clinica : "Valoracion global para mejorar la calidad asistencial de las PVVIH",
	h_sierra_norte : "Estudio Hospital Sierra Norte",
	facturacion : "Facturacion",
	pdl1 : "Determinacion de PD-L1 en biopsia liquida en pacientes con carcer de pulmon no de celula pequeña",
	conizaciones : "Recogida de datos para manejo de trabajos y articulos",
	opengeohealth : "OpenGeoHealth",
	ohsiris : "Open Health Space Infrastructure for Research and Industrial Services",
	formacion: "Formación"
};
