import React, { useState } from 'react';
import {Table, Popconfirm, Form, Drawer, Button, message} from 'antd';

import {CopyOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {transformField} from "../../../utils/TransformFormDashboard";
import {FormWithAction} from "../FormWithAction";
import {DrawerForm} from "./DrawerForm";
import {connect} from "react-redux";
import {selectFormRow} from "../../../services/actions/Form";
import FormService from "../../../services/api/form";
import utils from "../../../utils";



const TableForm = ({id, title, columns, rows, fields, selectFormRow}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(rows);
  const [visible, setVisible] = useState(false);
  const [editingKey, setEditingKey] = useState('');

  console.log("renderiza tableform")
  const sortDate = ((a, b) => {
    let keysA = Object.keys(a);
    let keysB = Object.keys(b);

    // Verificar si el primer atributo tiene "fecha" en el nombre
    if (keysA[0] && keysA[0].toLowerCase().includes("fecha") && keysB[0] && keysB[0].toLowerCase().includes("fecha")) {
      return new Date(a[keysA[0]]) - new Date(b[keysB[0]]);
    }
    return 0;  // Si no hay atributo con "fecha" en el primer elemento, mantiene el orden original.
  });
  const showDrawer = () => {
    setVisible(true)
  };

  const onClose = () => {
    setVisible(false)

  };

  const cancel = () => {
    setEditingKey('');
  };



  const removeLine = async key => {
    const newData = [...data];
    const index = newData.findIndex(item => key === item.key);

    if(index != -1) {
      newData.splice(index, 1);
      setData(newData);
      setEditingKey('');

      FormService.save({
        id_form: utils.getGenericIdLocation(),
        id_patient: utils.getPatientLocation(),
        form: newData,
        idTable: id
      })
        .then(res => {
          message.success("Fila eliminada")
        })
    }

  }

  const saveFromDrawer = (row) => {
    const newData = [...data];
    const index = newData.findIndex(item => row.key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      setData(newData.sort(sortDate));
    }
    else {
      row.key = newData.length;
      newData.push(row);
      setData(newData.sort(sortDate));
    }

    FormService.save({id_form: utils.getGenericIdLocation(), id_patient: utils.getPatientLocation(), form: newData, idTable: id})
      .then(res => {
        message.success("Guardado realizado")
      })

    console.log(newData)
  }

  // const actionButton = [
  //   {
  //     title: '',
  //     dataIndex: 'operation',
  //     render: (text, record) => {
  //       const editable = isEditing(record);
  //       return editable ? (
  //         <span>
  //           <a
  //             href="javascript:;"
  //             onClick={() => save(record.key)}
  //             style={{
  //               marginRight: 8,
  //             }}
  //           >
  //             Guardar
  //           </a>
  //           <Popconfirm title="¿Desea descartar los cambios?" onConfirm={() => cancel(record.key)}>
  //             <a>Cancelar</a>
  //           </Popconfirm>
  //         </span>
  //       ) : (
  //         <>
  //           <a disabled={editingKey !== ''} onClick={() => edit(record)}>
  //           <EditOutlined />
  //         </a>
  //           <a onClick={() => {
  //             selectFormRow(record);
  //             showDrawer()
  //           } }>
  //             <EditOutlined />
  //
  //           </a></>
  //
  //       );
  //     },
  //   }
  // ];
  const actionButton = [
    {
      title: '',
      dataIndex: 'operation',
      render: (text, record) => (
            <a onClick={() => {
              selectFormRow(record);
              showDrawer()
            } }>
              <EditOutlined />

            </a>
        )
    },
    {
      title: '',
      dataIndex: 'clone',
      render: (text, record, index) => (
          <a onClick={() => {
            clone(record)
          } }>
            <CopyOutlined />

          </a>
      )
    },
  ];

  const clone = (row) => {
    const newData = [...data];
    newData.push({...row, key: newData.length});
    setData(newData.sort(sortDate));

    FormService.save({
      id_form: utils.getGenericIdLocation(),
      id_patient: utils.getPatientLocation(),
      form: newData,
      idTable: id
    })
    .then(res => {
      message.success("Fila copiada")
    })

  }

  const mergedColumns = columns.filter(col => (!col.hidden) && col.inputType!="line" ).map(col => {
    // if (!col.editable) {
    //   return col;
    // }
    return {
      ...col,
    };
  });
  return (
    <>
      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={data}
          columns={mergedColumns.concat(actionButton)}
          rowClassName="editable-row"
          scroll={{ x: 1 }}
          size="small"
          pagination={{
            onChange: cancel,
          }}
        />

      </Form>
      <div>
        <Button type="primary" onClick={() => {showDrawer(); selectFormRow(null)}}>
          <PlusOutlined /> Nueva Fila
        </Button>
        <Drawer
          title={title}
          width={"85%"}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80, backgroundColor: "#fafafb" }}
        >
          <DrawerForm
            fields={fields}
            visible={visible}
            save={saveFromDrawer}
            removeLine={removeLine}
            onClose={onClose}
          />

        </Drawer>
      </div>
    </>
  );
};

export default connect(null, { selectFormRow })(TableForm)
