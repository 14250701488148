import React, {useEffect, useState} from 'react';
import {Layout, Grid, Button} from 'antd';
import { connect } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import navigationConfig from "configs/NavigationConfig";
import { 
  SIDE_NAV_WIDTH, 
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP
} from 'constants/ThemeConstant';
import utils from 'utils';
import Utils from "../../utils";
import {Link} from "react-router-dom";
import {imageBase64} from "../../assets/svg/icon"
const { Content } = Layout;
const { useBreakpoint } = Grid;


export const AppLayout = ({ navCollapsed, navType, location }) => {

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if(isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const ErrorInternet = () => {
    return (
      <div className="h-100 bg-white">
        <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
          <div className="container">
            <div className="text-center mb-5">
              <img className="img-fluid max-width-300" src={imageBase64} alt="test2" />
              <h1 className="font-weight-bold mb-4">Lo sentimos, no tiene conexión a internet</h1>
            </div>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    // Función para actualizar el estado a online
    const goOnline = () => setIsOnline(true);

    // Función para actualizar el estado a offline
    const goOffline = () => setIsOnline(false);

    // Agregar listeners para los eventos online y offline
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    // Limpiar listeners cuando el componente se desmonte
    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);
  return (
    <Layout>
      <HeaderNav isMobile={isMobile}/>
      {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo}/> : null}
      <Layout className="app-container">
        {(isNavSide && !isMobile) && Utils.getIdHospital() != 'selector'  ? <SideNav routeInfo={currentRouteInfo} location={location}/> : null }
        <Layout className="app-layout" style={Utils.getIdHospital() != 'selector'  ? {paddingLeft: getLayoutGutter()} : null}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              {isOnline ? <AppViews/> : <ErrorInternet/>}
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } =  theme;
  return { navCollapsed, navType, locale }
};

export default connect(mapStateToProps)(React.memo(AppLayout));