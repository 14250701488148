import React from 'react'
import {Col, Form, Select} from "antd";
import {Field} from "./index";
import Utils from "../../utils";

const { Option } = Select;


export class FieldMultiselect extends Field {

  constructor(obj={}) {
    super(obj);
  }


  getComponent (form, fieldChanged) {
    return () => (
      <Col className={!Utils.evaluateFormula(this.condition, form) ? "display-none" : "visible"} xs={24} sm={24} md={this.size}>

      <Form.Item
        label={this.label}
        name={this.name}
        initialValue={this.value}
      >
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Seleccionar"
          optionFilterProp="children"
          onChange={fieldChanged}
          disabled={this.disabled}
          filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {this.options?.map(option => <Option key={option.key}>{option.label}</Option>)}
        </Select>
      </Form.Item>
      </Col>);
  }

  transformToField = (data) => {
    this.options = data.options;
    this.name = data.name;
    this.label = data.label;
    this.type = data.type;
    this.size = data.size ?? 6;
    this.value = data.value == "" ? undefined : this.transformValueToField(data.value);
    this.condition = data.condition ?? true;
    this.disabled = data.disabled ?? false;

    return this;
  }

  transformValueToField = (data) => {
    //Compruebo si viene de formulario o desde url
    return typeof data === 'string' ? data.split(',') : data
  }
}