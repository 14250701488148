import React from 'react';

import axios from "axios";
import {API_BASE_URL, HEADER_TOKEN} from "../../configs/AppConfig";
import {AUTH_TOKEN} from "../constants/Auth";
import {notification, Button} from "antd";
import history from "../../history";
import utils from "../../utils";

export const ApiService = axios.create({
  baseURL: API_BASE_URL,
  timeout: 600000
});

const insertProjectAndHospital = (data) => {
  const location = window.location;
  data.id_estudio = utils.getIdProject(location);
  data.id_centro = utils.getIdHospital(location);
  //Replico porque Jesús no pone nunca los mismos nombres Quitar cuando vuelva
  data.estudio = utils.getIdProject(location);
  data.centro = utils.getIdHospital(location);
  return data;
};

ApiService.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN);
  if (jwtToken) {
    config.headers[HEADER_TOKEN] = jwtToken;
    config.data = insertProjectAndHospital(config.data);
  }
  config.data.external = true
  return config
}, error => {
  // Do something with request error here
  notification.error({message: 'Error' });
  Promise.reject(error)
});

ApiService.interceptors.response.use(request => {
  if(request.data?.responseNotification ) {
    const key = `open${Date.now()}`;
    // const btn = (
    //   <Button type="primary" size="small" onClick={() => notification.close(key)}>
    //     Revisar
    //   </Button>
    // );
    notification.open({
      type: request.data?.responseNotification?.type,
      message: request.data?.responseNotification?.message,
      description: request.data?.responseNotification?.description,
      key})
  }
  return request;
})

export const handlerError = (error) => {
  let notificationParam = { message: 'Ocurrió un error', description: 'Algo no ha funcionado correctamente, revise la operación' };
  let location = window.location

  // Remove token and redirect
  if (error.response?.status === 401) {
    notificationParam.message = 'Authentication Fail';
    notificationParam.description = 'Please login again';
    if(!location.pathname.includes('auth'))
      localStorage.setItem('redirect', location.pathname+location.search);
    localStorage.removeItem(AUTH_TOKEN);
    history.push(utils.getRouteLogin());
    //Retraso la carga para que aparezca el mensaje de fallo
    setTimeout(() => location.reload(), 1000);
  }

  if (error.response?.status === 300) {
    notificationParam.message = error.response.data.titulo
    notificationParam.description = error.response.data.descripcion

  }
  if (error.response?.status === 404) {
    notificationParam.message = 'Not Found'
  }

  if (error.response?.status === 500) {
    notificationParam.message = 'Internal Server Error'
  }

  if (error.response?.status === 508) {
    notificationParam.message = 'Time Out'
  }

  notification.error(notificationParam);

  return Promise.reject(error);
};
