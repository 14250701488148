import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import utils from 'utils';
import { getUserMe } from "../services/actions/Auth";


function RouteInterceptor({ children, isAuthenticated, location, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: `${AUTH_PREFIX_PATH}/${utils.getProjectLocation(location)}/login`,
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export const Views = (props) => {
    const { locale, token, location, user, getUserMe } = props;
    const currentAppLocale = AppLocale[locale];
    const project = utils.getProjectLocation(location);
    if(location.pathname.split("/")[1] === 'auth' && location.pathname.split("/")[3]=='error' && !utils.isValidProject(project))
       localStorage.setItem('project', 'global');
    if(token && !user.username){
      getUserMe(token);

    }
    return utils.isValidProject(project) || location.pathname === '/' ?
      (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd}>
                <Switch>
                    <Route exact path="/">
                        {/*Redirige al proyecto a no ser que sea la primera vez que se entra que manda a global*/}
                        <Redirect to={`${APP_PREFIX_PATH}/${localStorage.getItem('project')? localStorage.getItem('project') : 'global'}`} />
                    </Route>
                    <Route path={AUTH_PREFIX_PATH+'/:project1'}>
                        <AuthLayout />
                    </Route>
                    <RouteInterceptor path={APP_PREFIX_PATH+'/:project3'} isAuthenticated={token}>
                        <AppLayout location={location}/>
                    </RouteInterceptor>
                </Switch>
            </ConfigProvider>
        </IntlProvider>
      ) :
      <Redirect
        to={{
          pathname: `${AUTH_PREFIX_PATH}/${localStorage.getItem('project') ?? 'global'}/error`,
          state: { from: location }
        }}
      />
}

const mapStateToProps = ({ theme, auth }) => {
    const { locale } =  theme;
    const { token, user } = auth;
    return { locale, token, user }
};

export default withRouter(connect(mapStateToProps, {getUserMe})(Views));