import { Widget } from './index';
import QRCode from 'react-qr-code';
import React from "react";
import Card from "antd/es/card";

export class WidgetQr extends Widget {

  constructor(obj={}){
    super(obj);
    this.value = obj.value;
  }

  getComponent() {
    return () => (
      <Card title={this.title} bodyStyle={{display: "flex", justifyContent: "center"}}>
        <div>
          <QRCode value={this.value} />
        </div>
      </Card>
    );
  }

  transformToWidget = (data) => {
    this.title = data.title;
    this.type = data.type;
    this.size = data.size;
    this.value = data.value ?? "test";
    return this;
  }
}