import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import store from './services/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';

function App() {
    useEffect(() => {
        const userAgent = navigator.userAgent;

        // Verificar si el navegador es Internet Explorer
        if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
            window.location.href = 'microsoft-edge:' + window.location.href;
        }
    }, []);
    return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={Views}/>
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
