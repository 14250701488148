import {ApiService, handlerError} from "../ApiService";
import {responseSetting} from "./transform/SettingTransform";
import utils from "../../../utils";

const SettingService = {};

SettingService.get = () => {
  return ApiService.post('', {tipo: "OPERACION_0"})
    .then(responseSetting)
    .catch(handlerError);
}

SettingService.save = (data) => {
  return ApiService.post('', {tipo: "OPERACION_0-1", configuracion: {id_estudio: utils.getIdProject(), ...data}})
    .then()
    .catch(handlerError);
}



export default SettingService