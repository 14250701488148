import React from 'react'
import { Card } from 'antd';
import ApexChart from "react-apexcharts";
import { apexPieChartDefaultOption } from 'constants/ChartConstant';
import PropTypes from 'prop-types'

const defaultOption = apexPieChartDefaultOption;

const Chart = props => {
	return (
		<ApexChart {...props} />
	)
}

const DonutChartWidget = props => {
	const { series, customOptions, labels, width, height, title, extra } = props
	let options = Object.assign({}, defaultOption);
	options.labels = labels
	options.plotOptions.pie.donut.labels.total.label = "Total"
	options.plotOptions.pie.donut.labels.total.formatter = function(w) {
		let total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
		return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}
	options.yaxis = {
		labels: {
			...customOptions?.yaxis?.labels,
			formatter: function (value) {
				return typeof value === 'number'
					? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
					: value;
			}
		},
	}
	options.legend = {
		position: 'right',  // Mueve la leyenda a la derecha
		horizontalAlign: 'right',  // Centra la leyenda verticalmente en el lado derecho
		floating: false,  // Evita que la leyenda flote
		offsetX: 0,
	};


	if(customOptions) {
		options = {...options, ...customOptions }

	}
	console.log(options)
	return (
		<Card title={title}>
			<div className="text-center">
				<Chart type="donut" options={options} series={series} width={width} height={height} />
				{extra}
			</div>
		</Card>
	)
}

DonutChartWidget.propTypes = {
	series: PropTypes.array.isRequired,
	labels: PropTypes.array,
	title: PropTypes.string,
	extra: PropTypes.element
}

DonutChartWidget.defaultProps = {
	series: [],
	labels: [],
	title: '',
	height: 300,
	width: '100%'
};

export default DonutChartWidget
